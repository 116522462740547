<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-else>
        <v-card-title
          class="py-0 pt-7"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
          </div>
          <div class="ml-1 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            large
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="
              toggleGraphModal({ show: false });
              reload();
            "
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form lazy-validation ref="volunteerForm">
            <v-row no-gutters justify="center">
              <v-col cols="11" v-if="this.type != 'view'">
                <v-text-field
                  outlined
                  dense
                  v-model="door_sale"
                  label="Door Sale"
                  color="#7253CF"
                  class="formFields"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions
          class="d-flex justify-end"
          v-if="(this.type === 'add' || 'edit') && this.type != 'view'"
        >
          <v-btn
            class="dialogAction-btnText text-capitalize px-7"
            height="45"
            color="#38227A"
            :loading="formLoading"
            @click="submitForm"
          >
            <span>Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  GAME_DOOR_SALE_GET_API,
  GAME_DOOR_SALE_PATCH_API,
} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
export default {
  name: "NoteModal",
  components: {},
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      door_sale: "",
      graphidgame: "",
    };
  },
  computed: {
    ...mapGetters({
      showGetters: "gameManagement/getGraphShow",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.showGetters;
      },
      set(value) {
        return this.toggleGraphModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.graphModal.type;
    },
    graphID() {
      return this.$store.state.gameManagement.graphModal.graphID;
    },
    dialogTitle() {
      if (this.type === "add") return "Offline Data";
      if (this.type === "edit") return "Edit Note";
      if (this.type === "view") return "View Note";
      else return "View Note ";
    },
    actionText() {
      if (this.type === "add") {
        return "Offline Data";
      } else {
        return "Save";
      }
    },
    toastMessage() {
      if (this.type === "add") {
        return "Offline Data Added Successfully.";
      } else {
        return "Note Detail Updated.";
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleGraphModal: "gameManagement/toggleGraphModal",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      this.getDetail();
    },
    closeModal() {
      this.loading = false;
      this.formLoading = false;
    },
    getDetail() {
      const successHandler = (res) => {
        this.door_sale = res.data.data.result.door_sale;
        this.graphidgame = res.data.data.result.id;
        console.log(res.data);
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        GAME_DOOR_SALE_GET_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res);
        this.formLoading = false;
        this.showToast({
          message: this.toastMessage,
          color: "s",
        });
        this.$emit("reload");
        location.reload();
        this.toggleGraphModal({ show: false });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["id"] = this.graphidgame;
      formData["door_sale"] = this.door_sale;

      Axios.request_PATCH(
        GAME_DOOR_SALE_PATCH_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    reload() {
      location.reload();
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-btn.dialogAction-btnText {
  border-radius: 10px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
</style>
